<template>
  <section id="dashboard-analytics">
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <div class="m-2">
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <legend class="bv-no-focus-ring col-form-label pt-0">
                    صورة المركز
                  </legend>
                  <div v-if="file || centerInfo.photo" class="image-after-upload">
                    <feather-icon
                      icon="XCircleIcon"
                      size="28"
                      class="image-after-upload-remove"
                      @click="
                        file = null;
                        centerInfo.photo = '';
                      "
                    />
                    <img
                      :src="
                        centerInfo.photo
                          ? centerInfo.photo.full_path + centerInfo.photo.file_name
                          : file
                      "
                      class="img-fluid"
                    />
                  </div>
                  <b-form-file v-else class="form-group-file" @change="uploadImage" />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <legend class="bv-no-focus-ring col-form-label pt-0">
                    شعار المركز
                  </legend>
                  <div
                    v-if="centerLogo || centerInfo.center_logo"
                    class="image-after-upload"
                  >
                    <feather-icon
                      icon="XCircleIcon"
                      size="28"
                      class="image-after-upload-remove"
                      @click="
                        centerLogo = null;
                        centerInfo.center_logo = '';
                      "
                    />
                    <img
                      :src="
                        centerInfo.center_logo
                          ? centerInfo.center_logo.full_path +
                            centerInfo.center_logo.file_name
                          : centerLogo
                      "
                      class="img-fluid"
                    />
                  </div>
                  <b-form-file
                    v-else
                    class="form-group-file"
                    @change="uploadCenterLogo"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="اسم المركز" class="required">
                  <b-form-input v-model="centerInfo.name" placeholder="ادخل اسم المركز" />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="رقم الترخيص" class="required">
                  <b-form-input
                    v-model="centerInfo.license_number"
                    placeholder="ادخل رقم الترخيص"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="الإيميل" class="required">
                  <b-form-input v-model="centerInfo.email" placeholder="ادخل الإيميل" />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  class="header-content-form-group position-relative required"
                >
                  <label>كلمة المرور</label>
                  <b-form-input
                    v-model="centerInfo.password"
                    placeholder="*********"
                    :type="typePassword"
                    dir="rtl"
                  />
                  <span
                    @click="
                      typePassword == 'password'
                        ? (typePassword = 'text')
                        : 'password' || typePassword == 'text'
                        ? (typePassword = 'password')
                        : 'text'
                    "
                  >
                    <eye-icon
                      class="show-password"
                      :class="typePassword == 'text' ? 'show-password-active' : ''"
                  /></span>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  class="header-content-form-group position-relative required"
                >
                  <label>تأكيد كلمة المرور</label>
                  <b-form-input
                    v-model="centerInfo.password_confirmation"
                    placeholder="*********"
                    :type="typeConfigPassword"
                    dir="rtl"
                  />
                  <span
                    @click="
                      typeConfigPassword == 'password'
                        ? (typeConfigPassword = 'text')
                        : 'password' || typeConfigPassword == 'text'
                        ? (typeConfigPassword = 'password')
                        : 'text'
                    "
                  >
                    <eye-icon
                      class="show-password"
                      :class="typeConfigPassword == 'text' ? 'show-password-active' : ''"
                  /></span>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="رقم الهاتف" class="required">
                  <b-form-input
                    v-model="centerInfo.phone"
                    placeholder="ادخل رقم الهاتف"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="رقم الهاتف اخر">
                  <b-form-input
                    v-model="centerInfo.other_phone"
                    placeholder="ادخل رقم الهاتف اخر"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="تحديد العنوان" class="required">
                  <b-form-input
                    v-model="centerInfo.address"
                    placeholder="ادخل العنوان "
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="أيام الدوام" class="required">
                  <vSelect
                    v-model="centerInfo.working_days"
                    :options="workingDays"
                    :menu-props="{ maxHeight: '400' }"
                    multiple
                    persistent-hint
                    placeholder="حدد أيام الدوام "
                    dir="rtl"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="ساعات الدوام " class="required">
                  <vSelect
                    v-model="centerInfo.working_hours"
                    :options="workingHours"
                    placeholder="حدد ساعات الدوام "
                    dir="rtl"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="نسبة منصة مؤهل (%)" class="required">
                  <b-form-input
                    v-model="centerInfo.platform_ratio"
                    placeholder="--------"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="وصف المركز" class="required">
                  <b-form-textarea
                    v-model="centerInfo.description"
                    rows="5"
                    placeholder="ادخل وصف المركز"
                    no-resize
                  />
                </b-form-group>
              </b-col>
              <b-col md="12" class="mt-2 d-flex justify-content-between">
                <button onclick="history.back()" class="filter-main-btn">
                  إلـــــــــغــــــــاء
                </button>
                <button
                  :disabled="
                    centerInfo.name == '' ||
                    centerInfo.email == '' ||
                    centerInfo.phone == '' ||
                    centerInfo.photo == '' ||
                    centerInfo.center_logo == '' ||
                    centerInfo.address == '' ||
                    centerInfo.working_days == '' ||
                    centerInfo.working_hours == '' ||
                    centerInfo.description == '' ||
                    centerInfo.platform_ratio == '' ||
                    centerInfo.password == '' ||
                    centerInfo.password != centerInfo.password_confirmation
                  "
                  class="add-main-btn"
                  @click="submitForm"
                >
                  حـــــفــــظ
                </button>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import vSelect from "vue-select";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
  BFormFile,
  BFormTextarea,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    vSelect,
    BInputGroup,
    BFormGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormFile,
    BFormTextarea,
  },
  data() {
    return {
      typePassword: "password",
      typeConfigPassword: "password",
      file: null,
      centerLogo: null,
      selectedOptions: "كل أيام الاسبوع",
      workingDays: [
        "كل أيام الاسبوع",
        "السبت",
        "الاحد",
        "الاثنين",
        "الثلاثاء",
        "الاربعاء",
        "الخميس",
        "الجمعة",
      ],
      workingHours: [
        "من 8 صباحاً الى 8 مساءاً ",
        "من 8 صباحاً الى 10 مساءاً ",
        "من 8 صباحاً الى 12 مساءاً ",
        "من 9 صباحاً الى 8 مساءاً ",
        "من 9 صباحاً الى 10 مساءاً ",
        "من 9 صباحاً الى 12 مساءاً ",
      ],
      centerInfo: {
        id: "",
        name: "",
        phone: "",
        other_phone: "",
        center_logo: "",
        photo: "",
        email: "",
        address: "",
        working_days: "",
        working_hours: "",
        platform_ratio: "",
        license_number: "",
        description: "",
        password: "",
        password_confirmation: "",
      },
    };
  },
  computed: {
    ...mapGetters({}),
  },
  watch: {},
  created() {},
  methods: {
    ...mapActions({
      addCenter: "addCenter",
      uploadFiles: "uploadFiles",
    }),
    uploadImg(file, type) {
      const formData = new FormData();
      formData.append("file", file);
      this.uploadFiles(formData).then((response) => {
        if (type == "photo") {
          this.centerInfo.photo = response.content;
        } else {
          this.centerInfo.center_logo = response.content;
        }
      });
    },
    uploadImage(event) {
      const input = event.target;
      if (input.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.file = e.target.result;
        };
        // this.file = input.files[0];
        reader.readAsDataURL(input.files[0]);
        this.uploadImg(event.target.files[0], "photo");
      }
    },
    uploadCenterLogo(event) {
      const input = event.target;
      if (input.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.centerLogo = e.target.result;
        };
        // this.file = input.files[0];
        reader.readAsDataURL(input.files[0]);
        this.uploadImg(event.target.files[0], "center_logo");
      }
    },
    submitForm() {
      this.addCenter({
        name: this.centerInfo.name,
        email: this.centerInfo.email,
        password: this.centerInfo.password,
        password_confirmation: this.centerInfo.password_confirmation,
        phone: this.centerInfo.phone,
        other_phone: this.centerInfo.other_phone,
        address: this.centerInfo.address,
        working_days: JSON.stringify(this.centerInfo.working_days),
        working_hours: this.centerInfo.working_hours,
        platform_ratio: this.centerInfo.platform_ratio,
        license_number: this.centerInfo.license_number,
        description: this.centerInfo.description,
        photo:
          this.centerInfo.photo && this.centerInfo.photo.file_name
            ? this.centerInfo.photo.file_name
            : null,
        center_logo:
          this.centerInfo.center_logo && this.centerInfo.center_logo.file_name
            ? this.centerInfo.center_logo.file_name
            : null,
      }).then((resp) => {
        this.$router.push({
          name: "centers",
        });
      });
    },
  },
  mounted() {},
};
</script>
